import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

const AddProduct = () => {
  const [productname, setProductname] = useState('');
  const [productimage, setProductimage] = useState(null);
  const [message, setMessage] = useState('');

  const navigate = useNavigate(); // Create a navigate function

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('productname', productname);
    formData.append('productimage', productimage);

    try {
      const response = await axios.post('https://api.hyginewatersolution.com/api/addproduct', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201) {
        setMessage('Product added successfully!');
      } else {
        setMessage('Error: Could not add product');
      }
    } catch (error) {
      console.error('Error adding product:', error);
      setMessage(`Error: ${error.response?.data?.error || 'Unknown error'}`);
    }
  };

  const handleViewProducts = () => {
    navigate('/view-products'); // Navigate to the view-products page
  };

  return (
    <div className="form-container">
      <h2>Add Product</h2>
      {message && <p className="message">{message}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Product Name:</label>
          <input
            type="text"
            value={productname}
            onChange={(e) => setProductname(e.target.value)}
            required
          />
        </div>

        <div>
          <label>Product Image:</label>
          <input
            type="file"
            onChange={(e) => setProductimage(e.target.files[0])}
            required
          />
        </div>

        <button type="submit">Add Product</button>
      </form>
      
      {/* Button to view all products */}
      <button onClick={handleViewProducts} className="view-products-button">
        View Products
      </button>
    </div>
  );
};

export default AddProduct;
